var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "TenantFormBase-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "template",
            { slot: "headerSlot" },
            [
              _c("v-button", {
                attrs: { text: "返回" },
                on: { click: _vm.goBack },
              }),
            ],
            1
          ),
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属渠道",
                        rules: [
                          {
                            required: true,
                            message: "请输入公司名称",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入所属渠道",
                          maxlength: 36,
                          width: _vm.width,
                          disabled: true,
                        },
                        model: {
                          value: _vm.form.tenantChannelName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "tenantChannelName", $$v)
                          },
                          expression: "form.tenantChannelName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "可管项目数",
                        rules: [
                          {
                            required: true,
                            message: "请输入可管项目数",
                            trigger: "blur",
                          },
                        ],
                        prop: "communityMaxNum",
                      },
                    },
                    [
                      _c("v-input-number", {
                        attrs: {
                          placeholder: "请输入数字",
                          max: 9999,
                          controls: "",
                          controlsPosition: "right",
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.communityMaxNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "communityMaxNum", $$v)
                          },
                          expression: "form.communityMaxNum",
                        },
                      }),
                      _c("label", { staticClass: "tips" }, [_vm._v("个")]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "授权有效期",
                        rules: [
                          {
                            required: true,
                            message: "请输入授权有效期",
                            trigger: "blur",
                          },
                        ],
                        prop: "termOfValidity",
                      },
                    },
                    [
                      _c("span", { staticClass: "register" }, [
                        _vm._v("注册生效后"),
                      ]),
                      _c("v-input-number", {
                        attrs: {
                          placeholder: "请输入授权有效期",
                          max: 9999,
                          controls: "",
                          controlsPosition: "right",
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.termOfValidity,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "termOfValidity", $$v)
                          },
                          expression: "form.termOfValidity",
                        },
                      }),
                      _c("label", { staticClass: "tips" }, [_vm._v("个月")]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "产品域",
                        rules: [
                          {
                            required: true,
                            message: "请选择产品域名称",
                            trigger: "change",
                          },
                        ],
                        prop: "productRegion",
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              placeholder: "查询产品域名称",
                              width: _vm.width,
                            },
                            on: { onChange: _vm.productRegionChange },
                            model: {
                              value: _vm.form.productRegion,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "productRegion", $$v)
                              },
                              expression: "form.productRegion",
                            },
                          },
                          "v-select2",
                          _vm.productRegionParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "业务域",
                        rules: [
                          {
                            required: true,
                            message: "请选择业务域名称",
                            trigger: "change",
                          },
                        ],
                        prop: "businessRegion",
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              placeholder: "查询业务域名称",
                              width: _vm.width,
                            },
                            on: { onChange: _vm.businessDomainChange },
                            model: {
                              value: _vm.form.businessRegion,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "businessRegion", $$v)
                              },
                              expression: "form.businessRegion",
                            },
                          },
                          "v-select2",
                          _vm.businessDomainParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "管理员角色",
                        prop: "roleId",
                        rules: [
                          {
                            required: true,
                            message: "请选择管理员角色",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              subjoin: _vm.rolesExtraParams,
                              placeholder: "查询角色权限",
                              width: _vm.width,
                              handleData: _vm.handleData,
                            },
                            on: { onChange: _vm.roleChange },
                            model: {
                              value: _vm.form.roleId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "roleId", $$v)
                              },
                              expression: "form.roleId",
                            },
                          },
                          "v-select2",
                          _vm.roleParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "授权应用",
                        rules: [
                          {
                            required: true,
                            message: "请选择授权应用",
                            trigger: "blur",
                          },
                        ],
                        prop: "applicationInfo",
                      },
                    },
                    [
                      _c("chosen-list-panel", {
                        attrs: {
                          list: _vm.form.applicationInfo,
                          textAs: "subName",
                        },
                        on: {
                          "update:list": function ($event) {
                            return _vm.$set(_vm.form, "applicationInfo", $event)
                          },
                          select: function ($event) {
                            _vm.showMultiSelect = true
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("multi-select", {
        attrs: {
          title: "应用列表",
          isShow: _vm.showMultiSelect,
          searchUrl: _vm.getApplicationListURL,
          headers: _vm.headers,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          responseKey: _vm.responseKey,
          backFill: _vm.form.applicationInfo,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.showMultiSelect = $event
          },
          "update:is-show": function ($event) {
            _vm.showMultiSelect = $event
          },
          "update:backFill": function ($event) {
            return _vm.$set(_vm.form, "applicationInfo", $event)
          },
          "update:back-fill": function ($event) {
            return _vm.$set(_vm.form, "applicationInfo", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "应用名称" },
                  model: {
                    value: _vm.searchParams.subName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "subName", $$v)
                    },
                    expression: "searchParams.subName",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "应用类别", options: _vm.subHandleOps },
                  model: {
                    value: _vm.searchParams.subHandle,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "subHandle", $$v)
                    },
                    expression: "searchParams.subHandle",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "应用类型", options: _vm.subTypeOps },
                  model: {
                    value: _vm.searchParams.subType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "subType", $$v)
                    },
                    expression: "searchParams.subType",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "应用描述" },
                  model: {
                    value: _vm.searchParams.subNote,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "subNote", $$v)
                    },
                    expression: "searchParams.subNote",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }